<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
        <h2 class="brand-text brand-text-c text-primary ml-1">
          {{ appNameLong }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ appNameLong }}
          </b-card-title>
          <b-card-text class="mb-2">
            Giriş yapmak için kullanıcı bilgilerinizi giriniz.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- username -->
              <b-form-group
                label="Kullanıcı Adı"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="Username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="eyaz"
                  />
                  <b-form-invalid-feedback
                    id="login-username"
                    name="login-username"
                    class="text-danger"
                  >
                    Lütfen geçerli bir kullanıcı adı giriniz.
                  </b-form-invalid-feedback>
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Şifre</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="Password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback
                    id="login-password"
                    name="login-password"
                    class="text-danger"
                  >
                    Lütfen geçerli bir şifre giriniz.
                  </b-form-invalid-feedback>
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Beni Hatırla
                </b-form-checkbox>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isLoading"
                @click="validationForm"
              >
                <div v-show="!isLoading">
                  Giriş Yap
                </div>
                <div v-show="isLoading">
                  Yükleniyor...
                </div>
              </b-button>
              <b-link
                class="mt-2"
              >
                <small>Şifrenizi mi unuttunuz?</small>
              </b-link>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Hesabınız yok mu? </span>
            <b-link :to="{name: 'signUpAccount'}">
              <span>&nbsp;Hesap Oluştur</span>
            </b-link>
          </b-card-text>

          <b-modal
            id="signup-modal"
            cancel-variant="outline-danger"
            ok-title="İleri"
            cancel-title="Çıkış Yap"
            centered
            no-close-on-backdrop
            :hide-header-close="true"
            title="Hesap Seçimi"
            @ok="onModalSignUpOk"
            @cancel="onModalSignUpCancelled"
          >
            <sign-up-landing @signUpTypeChanged="onSignUpTypeChanged" />
          </b-modal>

          <!-- divider
          <div class="divider my-2">
            <div class="divider-text">
              ya da
            </div>
          </div>  -->

          <!-- social buttons
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
          -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BModal, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { mapActions, mapGetters } from 'vuex'
import SignUpLanding, { SignUpType } from '@/views/usersignup/SignUpLanding.vue'
import * as auth from '@/utils/auth'
import { assignUserGroup } from '@/api/auth'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BModal,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    SignUpLanding,
    BFormInvalidFeedback,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const { appLogoImage, appNameLong } = $themeConfig.app
    return { appLogoImage, appNameLong }
  },
  data() {
    return {
      Password: '',
      Username: '',
      status: '',
      isLoading: false,
      errorMessage: 'başarısız',
      signUpType: '',
      sideImg: require('@/assets/images/pages/n10back.png'),
      // validation rulesimport store from '@/store/index'
      required,
    }
  },
  computed: {
    ...mapGetters({
      authUsername: 'auth/GET_USERNAME',
      authFirstName: 'auth/GET_FIRSTNAME',
      authLastName: 'auth/GET_LASTNAME',
      authenticated: 'auth/GET_AUTHSTATE',
    }),
    currentRouteName() {
      return this.$route.name
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/n10back-dark.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    if (this.authUsername != null) {
      this.Username = this.authUsername
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/action_login',
      signOut: 'auth/action_logout',
    }),
    validationForm() {
      if (this.Username.length === 0 && this.Password === 0) return
      this.$refs.loginValidation.validate().then(success => {
        if (!success) return
        const userValidationObj = { UserName: this.Username, Password: this.Password }
        this.isLoading = true
        this.signIn(userValidationObj).then(res => {
          this.isLoading = false
          if (!res) return
          const userObj = auth.getUserObject()
          const userGroup = userObj.UserGroupId
          if (userGroup === '0') {
            this.$bvModal.show('signup-modal')
            this.signOut()
            return
          }
          this.$router.push({ name: 'home' })
        }).catch(err => {
          this.isLoading = false
          if (err !== undefined) {
            this.errorMessage = err.errorCode === 400 ? `Error:${err.message}` : err
          }
          // if (err.message =='Pending for E-Mail validation') {
          //
          // }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Giriş',
              text: this.errorMessage.toString(),
              icon: 'EditIcon',
              type: 'error',
            },
          })
        })
      })
      this.isLoading = false
    },
    onModalSignUpOk() {
      const userGroup = {
        username: this.Username,
        password: this.Password,
        groupId: 0,
      }
      if (this.signUpType === SignUpType.Applicant) {
        userGroup.groupId = 4
        assignUserGroup(userGroup).then(() => {
          this.signIn({ username: this.Username, password: this.Password }).then(() => {
            // this.$router.push({ name: 'signUpApplicant' })
            this.$router.push({ name: 'home' })
          })
        })
      } else if (this.signUpType === SignUpType.Employer) {
        userGroup.groupId = 3
        assignUserGroup(userGroup).then(() => {
          this.signIn({ username: this.Username, password: this.Password }).then(() => {
            this.$router.push({ name: 'signUpEmployer' })
          })
        })
      }
    },
    onModalSignUpCancelled() {
      this.signOut()
    },
    onSignUpTypeChanged(type) {
      this.signUpType = type
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.brand-text-c {
  display: flex;
  align-items: center;
}
</style>
